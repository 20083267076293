<template>
  <div class="card-panel elevation-3">
    <div class="card-panel-icon-wrapper icon-toolbar">
      <v-icon class="card-panel-icon">
        {{ icon }}
      </v-icon>
    </div>
    <div class="card-panel-description">
      <div class="card-panel-text">
        {{ text }}
      </div>
      <count-to
        v-if="endValue"
        :start-val="0"
        :end-val="endValue"
        :duration="2600"
        decimal=","
        separator="."
        class="card-panel-num"
      />
      <div class="card-panel-num" v-if="currency">
        G$ {{ currency | _guarani }}
      </div>
      <div v-if="currency" class="text-caption mt-n2">
        custo medio
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
  name: "CardPanel",
  components: {
    CountTo,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    endValue: {
      type: Number,
      default: 0,
    },
    currency: {
      type: [Number, String],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.card-panel {
  height: 108px;
  color: #666;
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 26px 16px 10px;

  &:hover {
    .card-panel-icon-wrapper {
      color: #fff;
    }
    .icon-toolbar {
      background: #f8ae25;

      i {
        color: #fff !important;
      }
    }
  }
  .icon-toolbar {
    i {
      color: #f8ae25 !important;
    }
  }

  .card-panel-icon-wrapper {
    padding: 16px;
    transition: all 0.38s ease-out;
    border-radius: 6px;
  }
  .card-panel-icon {
    font-size: 48px;
  }
  .card-panel-description {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .card-panel-text {
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
      margin-bottom: 0px;
    }
    .card-panel-num {
      font-size: 24px;
      text-align: right;
      font-weight: bold;
    }
  }
}

// @media (max-width: 550px) {
//   .card-panel-description {
//     display: none;
//   }
//   .card-panel-icon-wrapper {
//     float: none !important;
//     width: 100%;
//     height: 100%;
//     margin: 0 !important;
//     .svg-icon {
//       display: block;
//       margin: 14px auto !important;
//       float: none !important;
//     }
//   }
// }
</style>
